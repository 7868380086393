import { Link } from "gatsby"
import React, { Component } from "react"
import he from 'he';
import moment from 'moment';
import 'moment/locale/nl';
import rehypeRaw from "rehype-raw";
import Markdown from "react-markdown";

class News extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        const backgroundImage = {
            backgroundImage: `url(${process.env.IMAGE_BASE_URL}${this.props.item.Image.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center"
        }

        const date = moment(this.props.item.Date, 'YYYY-MM-DD');
        const header = he.decode((this.props.item.Title)).replace(/(<([^>]+)>)/gi, "")
        const intro = he.decode((this.props.item.Content)).replace(/(<([^>]+)>)/gi, "")
        const newsMain = <div className={'col-12'}>
            <div className={'container f-container small'}>
                <div className={'col-6 pt-30 pb-30'}>
                    <h2 className={'bold t-34 lh-100 mb-20'}  dangerouslySetInnerHTML={{ __html: header }} />
                    <div className={'bold mb-15'}>{ date.locale('nl').format('LL')}</div>
                    <div className={'lc-7'} dangerouslySetInnerHTML={{ __html: intro }}/>
                    <div className={'test'}><Markdown
                      className={'lc-3'}
                      children={intro}
                      rehypePlugins={[rehypeRaw]}
                      escapeHtml={false} /></div>
                    <div>
                        {<Link to={`/nieuws/${this.props.item.Slug}`} className={`mt-30 blue-button`}>Lees meer
                        </Link>}
                    </div>
                </div>
                <div className={"col-6 block"} style={backgroundImage}/>
            </div>
        </div>

        const news = <div className={'col-6 news-article'}>
            <div className={`container f-container full-height small ${this.props.isBlue ? 'blue' : 'white'}`}>
                {this.props.type === 'even' ? <div className={'col-4'}/> : ''}
                <div className={'col-8 pb-30'}>
                    <Link to={`/nieuws/${this.props.item.Slug}`}>
                    <div className={`block h-250 mb-30 mt-30 ${this.props.type === 'even' ? 'mr-15' : 'ml-15'}`} style={backgroundImage}/>
                    <div className={`news-info ${this.props.isBlue ? 'white-text' : 'deep-blue-text'} ${this.props.type === 'even' ? 'mr-15' : 'ml-15'} mb-20`}>
                        <h2 className={'bold t-34 lh-100 mb-20'}  dangerouslySetInnerHTML={{ __html: header }} />
                        <div className={'bold mb-15'}>{ date.locale('nl').format('LL')}</div>
                        {/*<div className={'lc-3'} dangerouslySetInnerHTML={{ __html: intro }}/>*/}

                        <div>
                          <Markdown
                          className={'lc-3'}
                          children={intro}
                          rehypePlugins={[rehypeRaw]}
                          skipHtml={true}
                          disallowedElements={['p', 'strong', 'em', 'h3', 'h5']}
                          unwrapDisallowed={true}
                          escapeHtml={false} />
                        </div>

                        <div>
                            {<Link to={`/nieuws/${this.props.item.Slug}`} className={`mt-30 ${this.props.isBlue ? 'white-button' : 'blue-button'}`}>Lees meer
                            </Link>}
                        </div>
                    </div>
                    </Link>
                </div>
                {this.props.type === 'odd' ? <div className={'col-4'}/> : ''}
            </div>
        </div>

        return (
            <>
                {this.props.type === 'main' ? newsMain : news}
            </>
        )
    }

}


export default News
